<script setup lang="ts">
import Dialog from 'primevue/dialog'
import { ref } from 'vue'

const display = ref(false)
const _type = ref("")
const _message = ref("")

let _confirmCallback: Function | undefined = undefined

const emits = defineEmits<{
  cancel: []
}>()

function showNotification(type: string, message: string, confirmCallback?: Function,) {
  display.value = true
  _type.value = type
  _message.value = message
  _confirmCallback = confirmCallback
}

function closeNotification() {
  display.value = false
  if (_confirmCallback) {
    _confirmCallback()
  }
  _confirmCallback = undefined
}


defineExpose({
  showNotification, closeNotification
})

</script>
<template>
  <Dialog v-model:visible="display" :draggable="false" class="custom-dialog" :closable="false" modal>
    <template #header>
      <div class="dialog-header">
        <p v-if="_type == 'Success'">{{ $t('popup.notice_success') }}</p>
        <p v-else-if="_type == 'Warning'">{{ $t('popup.notice_unsuccess') }}</p>
      </div>
    </template>
    <div>
      <p class="dialog-des" style="margin-bottom: 0;">{{ _message }}</p>
    </div>
    <template #footer>
      <div class="footer-btn-box">
        <button class="custom btn action-btn active" @click="closeNotification">{{ $t('common.okay') }}</button>
      </div>
    </template>
  </Dialog>
</template>
<style lang="scss" scoped>
@import "@/style/default.scss";
@import "@/style/dialog.scss";
</style>