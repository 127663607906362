import axios from "axios"
import type { RequestDynamicJson } from "core/domain/request/requestDynamic"
import { locale } from "core/service/LocaleService"

const BACKTEST_URL = import.meta.env.PROD ? `https://api.${import.meta.env.VITE_DOMAIN}.com` : "https://192.168.0.24:8100"

export async function ContactMailAPI(email: string, content: string) {

  const formData = new FormData()
  formData.append('email', email)
  formData.append('content', content)

  return await axios({
    baseURL: BACKTEST_URL,
    url: `/contact/mail`,
    method: "POST",
    data: formData
  })
}

export async function backtestDynamicAPI(request: RequestDynamicJson, type: string, benchmark: boolean = true) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/backtest/${type}`,
    method: "PUT",
    data: request,
    params: {
      benchmark
    }
  })
}

export async function GetUserFavoriteEtfListAPI(uuid: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/${uuid}/asset`,
    method: "GET",
  })
}

export async function AddUserFavoriteEtfAPI(uuid: string, symbol: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/${uuid}/asset/${symbol}`,
    method: "POST",
  })
}

export async function RemoveUserFavoriteEtfAPI(uuid: string, symbol: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/${uuid}/asset/${symbol}`,
    method: "DELETE",
  })
}

export async function GetUserWithDrawAPI() {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/withdraw`,
    method: "GET",
  })
}

export async function WithdrawUserConsentAPI(target: string, field: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/user/consent`,
    method: "PUT",
    params: {
      target,
      field
    }
  })
}

export async function GetAssetListAPI(query: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: "/public/asset" + query,
    method: "GET",
  })
}

export async function GetAssetStatisticsListAPI(query: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: "/public/asset/statistics" + query,
    method: "GET",
  })
}

export async function GetAssetDividendListAPI(market: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: "/public/asset/dividend",
    method: "GET",
    params: {
      market,
    }
  })
}


export async function GetAssetQueryAPI(tickerList: string[]) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: "/public/asset/query",
    method: "GET",
    params: {
      ticker: tickerList
    }
  })
}

export async function GetIndexListAPI() {
  return await axios({
    baseURL: BACKTEST_URL,
    url: "/public/index",
    method: "GET",
  })
}


export async function GetAssetScatterAPI() {
  return await axios({
    baseURL: BACKTEST_URL,
    url: "/public/asset/scatter",
    method: "GET",
  })
}

export async function GetAssetDetailAPI(symbol: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    headers: { 'Accept-language': locale },
    url: `/public/asset/${symbol}`,
    method: "GET",
  })
}

export async function GetAssetPeerAPI(symbol: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/asset/${symbol}/peer`,
    method: "GET",
  })
}

export async function GetAssetReturnAPI(symbol: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/asset/${symbol}/return`,
    method: "GET",
  })
}

export async function GetAssetMovingAverageAPI(symbol: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/asset/${symbol}/ma`,
    method: "GET",
  })
}

export async function GetAssetPeerNavAPI(tickerList: string[]) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/asset/nav`,
    method: "GET",
    params: {
      ticker: tickerList
    }
  })
}

export async function GetAssetPeerMomentumAPI(tickerList: string[]) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/asset/momentum`,
    method: "GET",
    params: {
      ticker: tickerList
    }
  })
}

export async function GetStrategyAssetCorrAPI() {
  return await axios({
    baseURL: BACKTEST_URL,
    url: '/public/asset/strategy/corr',
    method: "GET",
  })
}

export async function GetDashboardPeriodReturnAPI(dateFrom: string, dateTo: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/asset/dashboard/period-return`,
    method: "GET",
    params: {
      date_from: dateFrom,
      date_to: dateTo,
    }
  })
}

export async function GetAssetDividendAPI(symbol: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/asset/${symbol}/dividend`,
    method: "GET",
  })
}

export async function GetAssetPriceAPI(symbol: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/asset/${symbol}/price`,
    method: "GET",
  })
}

export async function GetAssetRealtimeAPI(symbol: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/asset/${symbol}/realtime`,
    method: "GET",
  })
}

export async function GetAssetQuantileAPI(symbol: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/asset/${symbol}/quantile`,
    method: "GET",
  })
}

export async function GetSampleStrategyListAPI(name: string[], version : "" | 2 = "") {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/strategy/community/sample${version}`,
    method: "GET",
    params: {
      name
    }
  })
}

export async function GetSampleStrategyAPI(sample: string, version : "" | 2 = "") {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/strategy/community/sample${version}/` + sample,
    method: "GET",
  })
}

export async function GetStrategyListAPI(currency: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: "/public/strategy/list",
    method: "GET",
    headers: { 'Accept-language': locale },
    params: {
      currency
    }
  })
}

export async function GetStrategyCompareAPI() {
  return await axios({
    baseURL: BACKTEST_URL,
    url: "/public/strategy/compare",
    method: "GET",
  })
}

export async function GetStrategyJoinAPI(data: any) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: "/public/portfolio/join",
    method: "PUT",
    data
  })
}

export async function GetApplicationStrategyAPI(currency: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: "/admin/strategy/application",
    method: "GET",
    params: {
      currency
    }
  })
}

export async function GetStrategyMixAPI(currency: string, user?: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: "/public/strategy/mix",
    method: "GET",
    params: {
      user,
      currency
    }
  })
}

export async function GetStrategyCopyAPI(category: string, group: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/strategy/${category}/copy`,
    method: "GET",
    params: {
      group
    }
  })
}

export async function GetPortfolioReportAPI(uuid: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/portfolio/${uuid}/report`,
    method: "GET",
  })
}

export async function GetComparePortfolioAPI(uuidList: string[]) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/portfolio/compare`,
    method: "GET",
    params: {
      uuid_list: uuidList
    }
  })
}

export async function GetStrategyExampleAPI(url: string, currency: string, isLogin: boolean) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: (isLogin ? '' : '/public') + `/strategy/${url}/portfolio`,
    method: "GET",
    headers: { 'accept-language': locale },
    params: {
      currency
    }
  })
}

export async function GetStrategySampleAPI(url: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/sample/${url}`,
    method: "GET",
  })
}

export async function UserStrategyReferencePortfolioAPI() {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/reference/portfolio`,
    method: "GET",
  })
}

export async function UserStrategyReferenceAPI() {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/reference`,
    method: "GET",
  })
}

export async function ReferStrategyAPI(uuid: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/reference/${uuid}`,
    method: "POST",
  })
}

export async function DeleteStrategyReferenceAPI(uuid: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/reference/${uuid}`,
    method: "DELETE",
  })
}

export async function GetUserPortfolioListAPI(uuid: string, weight: boolean) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/${uuid}/portfolio`,
    method: "GET",
    params: {
      weight
    },
  })
}

export async function CreateOperationAPI(data: any) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/operation`,
    method: "POST",
    data
  })
}

export async function GetUserOperationAPI() {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/operation`,
    method: "GET",
  })
}

export async function GetOperationAPI(uuid: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/operation/${uuid}`,
    method: "GET",
  })
}

export async function GetOperationWeightAPI(uuid: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/operation/${uuid}/weight`,
    method: "GET",
  })
}

export async function GetPortfolioWeightAPI(uuid: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/portfolio/${uuid}/weight`,
    method: "GET",
  })
}

export async function GetOperationBalanceAPI(uuid: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/operation/${uuid}/balance`,
    method: "GET"
  })
}

export async function RebalanceOperationAPI(uuid: string, data: any) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/operation/${uuid}/rebalance`,
    method: "POST",
    data
  })
}

export async function ViewOrderAPI(uuid: string, code: 'ongoing' | 'latest') {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/operation/${uuid}/rebalance/${code}`,
    method: "GET",
  })
}

export async function CancelRebalancingAPI(uuid: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/operation/${uuid}/rebalance`,
    method: "DELETE",
  })
}

export async function UpdateOperationAPI(uuid: string, data: any) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/operation/${uuid}`,
    method: "PUT",
    data
  })
}

export async function DeleteOperationAPI(uuid: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/operation/${uuid}`,
    method: "DELETE"
  })
}

export async function CreateUserPortfolioAPI(uuid: string, type: string, title: string, data: any, overwrite: boolean) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/${uuid}/portfolio`,
    method: "POST",
    params: {
      type,
      title,
      overwrite
    },
    data,
  })
}

export async function DeleteUserPortfolioAPI(portfolioUUID: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/portfolio/${portfolioUUID}`,
    method: "DELETE"
  })
}

export async function UpdateUserPortfolioAPI(portfolioUUID: string, title?: string, share?: boolean, alarm?: boolean) {
  const params: { title?: string, share?: boolean, alarm?: boolean } = {}
  if (title) {
    params['title'] = title
  }
  if (share != undefined) {
    params['share'] = share
  }
  if (alarm != undefined) {
    params['alarm'] = alarm
  }
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/portfolio/${portfolioUUID}`,
    method: "PUT",
    params
  })
}

export async function SharePortfolioResultAPI(type: string, data: any) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/portfolio/share`,
    method: "POST",
    params: {
      type
    },
    data
  })
}

export async function GetSharePortfolioResultAPI(uuid: string, type: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/portfolio/share/${uuid}`,
    method: "GET",
    params: {
      type
    }
  })
}

export async function ResetPasswordAPI(uuid: string, newPW: string, confirmPW: string) {
  const formData = new FormData()
  formData.append('new_pw', newPW)
  formData.append('confirm_pw', confirmPW)

  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/user/${uuid}/password`,
    method: "PUT",
    data: formData
  })
}


export async function confirmPasswordAPI(email: string, code: string) {
  const formData = new FormData()
  formData.append('email', email)
  formData.append('code', code)

  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/user/password`,
    method: "POST",
    data: formData
  })
}

export async function LoginAPI(email: string, pw: string) {
  const formData = new FormData()
  formData.append('email', email)
  formData.append('pw', pw)

  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/user/login`,
    method: "POST",
    data: formData
  })
}

export async function LogoutAPI() {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/user/logout`,
    method: "DELETE",
  })
}

export async function refreshTokenAPI() {
  return await axios({
    method: "GET",
    baseURL: BACKTEST_URL,
    url: "/public/user/token",
  })
}

export async function GetTokenInfoAPI() {
  return await axios({
    method: "GET",
    baseURL: BACKTEST_URL,
    url: "/public/user/token/info",
  })
}

export async function confirmMailAPI(email: string, code: string) {
  const formData = new FormData()
  formData.append('email', email)
  formData.append('code', code)

  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/user/mail`,
    method: "POST",
    data: formData
  })
}

export async function ConfirmPhoneAPI(phone: string) {
  const formData = new FormData()
  formData.append('phone', phone)

  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/user/phone`,
    method: "POST",
    data: formData
  })
}

export async function AuthAPI(platform: string, data: any) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/user/auth/${platform}`,
    method: "POST",
    data
  })
}

export async function SignUpAPI(platform: string, data: any) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/user/signup/${platform}`,
    method: "POST",
    data
  })
}

export async function createUserAPI(email: string, newPW: string, confirmPW: string, firstName: string, lastName: string, country: string) {
  const formData = new FormData()
  formData.append('email', email)
  formData.append('new_password', newPW)
  formData.append('confirm_password', confirmPW)
  formData.append('first_name', firstName)
  formData.append('last_name', lastName)
  formData.append('country', 'korea')

  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/user`,
    method: "POST",
    data: formData
  })
}

export async function updateUserProfileAPI(uuid: string, nickname: string, phone: string, marketingConsent: boolean, newsletterConsent: boolean) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/${uuid}`,
    method: "PUT",
    data: {
      nickname,
      phone,
      marketing_consent: marketingConsent,
      newsletter_consent: newsletterConsent
    }
  })
}

export async function updateUserAlarmAPI(key: string, channel: string[]) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/alarm`,
    method: "PUT",
    params: {
      key,
      channel
    },
  })
}

export async function updateUserInvestTypeTestAPI(test: any) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/invest-type-test`,
    method: "PUT",
    data: test
  })
}

export async function GetUserNotificationAPI() {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/notification`,
    method: "GET",
  })
}

export async function UpdateUserNotificationAPI() {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/notification/unread`,
    method: "PUT",
  })
}

export async function addUserBrowserPushAPI(subscription: any) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/alarm/browser`,
    method: "POST",
    data: {
      subscription
    },
  })
}

export async function deleteUserAPI(uuid: string, reason: string) {
  const formData = new FormData()
  formData.append('reason', reason)

  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/${uuid}`,
    method: "DELETE",
    data: formData
  })
}

export async function RegisterBillingAPI(authKey: string, plan: string, planType: string, planPeriod: string) {
  const formData = new FormData()
  formData.append('auth_key', authKey)
  formData.append('plan', plan)
  formData.append('plan_type', planType)
  formData.append('plan_period', planPeriod)

  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/billing`,
    method: "POST",
    data: formData,
  })
}

export async function ChangeBillingAPI(authKey: string) {
  const formData = new FormData()
  formData.append('auth_key', authKey)

  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/billing`,
    method: "PUT",
    data: formData,
  })
}

export async function CreatePaymentOrderAPI(plan: string, planType: string, planPeriod: string) {
  const formData = new FormData()
  formData.append('plan', plan)
  formData.append('plan_type', planType)
  formData.append('plan_period', planPeriod)

  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/payment`,
    method: "POST",
    data: formData,
  })
}

export async function ConfirmPaymentOrderAPI(orderId: string, paymentKey: string, amount: string) {
  const formData = new FormData()
  formData.append('payment_key', paymentKey)
  formData.append('amount', amount)

  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/payment/${orderId}`,
    method: "PUT",
    data: formData,
  })
}

export async function DeletePaymentOrderAPI(orderId: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/payment/${orderId}`,
    method: "DELETE",
  })
}

export async function GetUserPaymentAPI() {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/payment`,
    method: "GET",
  })
}

export async function UnsubscribeAPI() {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/billing`,
    method: "DELETE",
  })
}

export async function UnsubscribeFeedbackAPI(content: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/feedback/unsubscribe`,
    method: "POST",
    data: {
      content
    }
  })
}

export async function ChangeUserPlanAPI(reasonCode: string, plan: string, period: 'M' | 'Y') {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/plan`,
    method: "PUT",
    data: {
      reason_code: reasonCode,
      plan,
      period
    }
  })
}

export async function RefundBacktestPassAPI(uuid: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/pass/${uuid}`,
    method: "PUT",
  })
}

export async function GetAdminChangePlanAPI(country: 'global' | 'korea') {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/admin/${country}/plan`,
    method: "GET",
  })
}

export async function ApproveChangePlanAPI(uuid: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/admin/user/plan/${uuid}`,
    method: "PUT",
  })
}

export async function GetExchangeRateAPI() {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/data/exchangeRate`,
    method: "GET",
  })
}

export async function GetPortfolioRankingAPI(market: 'US' | 'KR', year: number, field: 'cagr' | 'sharpe', leverage: boolean, pension?: 'RETIREMENT' | 'INDEPENDENT') {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/portfolio/ranking`,
    method: "GET",
    headers: { 'Accept-language': locale },
    params: {
      market,
      year,
      field,
      leverage,
      pension,
    }
  })
}

export async function GetTodayPerformanceAPI(country: 'global' | 'korea') {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/admin/${country}/performance/today`,
    method: "GET",
  })
}

export async function GetPerformanceChartAPI(country: 'global' | 'korea') {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/admin/${country}/performance-chart`,
    method: "GET",
  })
}


export async function GetUserFindRouteAPI(country: 'global' | 'korea') {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/admin/${country}/find-route`,
    method: "GET",
  })
}

export async function GetAdminUserAPI(country: 'global' | 'korea') {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/admin/${country}/user`,
    method: "GET",
  })
}

export async function CreateEtfAlarmAPI(uuid: string, data: any) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/${uuid}/etf/notification`,
    method: "POST",
    data,
  })
}

export async function GetEtfAlarmAPI(ticker: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/etf/${ticker}/notification`,
    method: "GET",
  })
}

export async function GetUserEtfAlarmAPI() {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/etf/notification`,
    method: "GET",
  })
}

export async function updateEtfAlarmAPI(uuid: string, data: any) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/etf/notification/${uuid}`,
    method: "PUT",
    data,
  })
}

export async function DeleteEtfAlarmAPI(uuid: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/user/etf/notification/${uuid}`,
    method: "DELETE",
  })
}

export async function CreateNoticeBannerAPI(content: string, lang: string, link?: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/admin/data/banner`,
    method: "POST",
    data: {
      content,
      link,
      lang
    },
  })
}

export async function GetNoticeBannerAPI(lang: 'en' | 'ko') {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/admin/data/banner`,
    method: "GET",
    params: {
      lang
    }
  })
}

export async function CreateApplicationStrategyAPI(data: any) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/admin/strategy/application`,
    method: "POST",
    data
  })
}

export async function GetEconomicChartAPI(economic: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/data/economic/${economic}`,
    method: "GET",
  })
}

export async function GetAdminEtfPriceAPI(symbols: string[]) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/admin/data/etf/price`,
    method: "GET",
    params: {
      symbols
    },
    responseType: "blob",
  })
}

export async function GetAdminEconomicDataAPI(code: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/admin/data/economic/${code}`,
    method: "GET",
    responseType: "blob",
  })
}

export async function GetAdminPortfolioResultAPI(uuid: string) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/admin/data/portfolio/${uuid}/report`,
    method: "GET",
    responseType: "blob",
  }) 
}

export async function PaypalLogAPI(data: any) {
  return await axios({
    baseURL: BACKTEST_URL,
    url: `/public/webhook/paypal/log`,
    method: "POST",
    data
  })
}
