export interface UserNotification {
  uuid: string
  title: string
  content: string
  url: string
  is_view: boolean
  created_at: string
}

export class UserNotification implements UserNotification {
  constructor(data: UserNotification) {
    this.uuid = data.uuid
    this.title = data.title
    this.content = data.content
    this.url = data.url
    this.is_view = data.is_view
    this.created_at = data.created_at
  }
}
