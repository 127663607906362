import { GetAssetListAPI, GetAssetStatisticsListAPI } from "core/apis/backtest"
import { sortAsset, Asset, type AssetInput } from "core/domain/asset"
import { defineStore } from "pinia"
import { useUserStore } from "./User"
import { locale } from "core/service/LocaleService"

export const useEtfStore = defineStore('etf', {
  state: (): {
    etfDataList: Asset[],
    market: 'ALL' | 'US' | 'KR',
    isFetchedStatistics: boolean
  } => {
    return {
      etfDataList: [],
      market: 'ALL',
      isFetchedStatistics: false
    }
  },
  getters: {
    getEtf: (state) => {
      return (symbol: string) => state.etfDataList.find((el: Asset) => el.symbol == symbol)
    },
    getAll: (state) => {
      return state.etfDataList.filter((asset) => {
        return state.market == 'ALL' || asset.market == state.market
      })
    },
    getStrategyEtf: (state) => {
      let STRATEGY_ETF: string[] = []

      if (state.market == 'KR') {
        STRATEGY_ETF = ['360750', '143850', '133690', '304940', '245340', '280930', '309230', '446720', '429000', '213630', '251350', '189400', '195970', '195980', '069500', '102110', '229200', '232080', '226490', '278540', '310970', '227570', '325010', '161510', '329750', '305080', '304660', '332620', '182490', '153130', '157450', '114260', '148070', '273130', '132030', '144600', '261220', '130680', '137610', '329200', '182480', '261240', '292560']
        const sel = state.etfDataList ? STRATEGY_ETF.map((symbol) => state.etfDataList.find((el: Asset) => el.symbol == symbol)!) : []
        return sortAsset(sel)
      } else {
        STRATEGY_ETF = ['AGG', 'BIL', 'BND', 'BNDX', 'BWX', 'IBIT', 'PDBC', 'EEM', 'EFA', 'EMB', 'EWJ', 'EZU', 'GLD', 'GSG', 'HYB', 'HYG', 'IEF', 'IWD', 'IWF', 'IWM', 'IWN', 'IWO', 'IYR', 'SCHH', 'LQD', 'MTUM', 'QQQ', 'REM', 'RWX', 'SCZ', 'SHV', 'SHY', 'SPY', 'TIP', 'TLT', 'VEU', 'VGK', 'VNQ', 'VT', 'VWO', 'XLB', 'XLE', 'XLF', 'XLI', 'XLK', 'XLP', 'XLU', 'XLV', 'XLY']
        return sortAsset(state.etfDataList ? state.etfDataList.filter((asset) => STRATEGY_ETF.includes(asset.symbol)) : [])
      }
    },
    getUserFavoriteEtf: (state) => {
      const userStore = useUserStore()
      if (userStore.userFavoriteEtfList) {
        return state.etfDataList.filter((asset) => userStore.userFavoriteEtfList!.includes(asset.symbol))
      } else {
        return []
      }
    },
    getAssetCategory() {
      const assetCategory: any = {}

      this.getAll.sort((a, b) => a.ord - b.ord).forEach((asset) => {
        if (asset.group == '현금' || asset.group == 'Cash') return

        const category1 = asset.group
        const category2 = asset.category
        const category3 = asset.category2 == 'Cryptocurrency' ? 'Crypto' : asset.category2
        const category4 = asset.segment

        if (assetCategory[category1] == undefined) {
          assetCategory[category1] = {}
        }

        if (assetCategory[category1][category3] == undefined) {
          assetCategory[category1][category3] = {}
        }

        if (assetCategory[category1][category3][category4] == undefined) {
          assetCategory[category1][category3][category4] = new Set()
        }

        assetCategory[category1][category3][category4].add(category2)
      })

      return assetCategory
    },
  },
  actions: {
    async fetchEtfList() {
      if (this.etfDataList.length > 0) return
      const assetResponse = await GetAssetListAPI(locale == 'ko' ? '' : '?us=true')
      this.etfDataList = assetResponse.data.map((input: AssetInput) => { return new Asset(input) })
    },
    async fetchEtfStatistics() {
      if (this.isFetchedStatistics) return
      const response = await GetAssetStatisticsListAPI(locale == 'ko' ? '' : '?us=true')
      this.etfDataList = this.etfDataList.map(el => {
        const etf = response.data.find((e: any) => e.symbol == el.symbol)
        el.statistics = etf?.statistics
        el.aum = etf?.total_assets
        el.yield = etf?.dividend_yield
        return el
      }) 
      this.isFetchedStatistics = true
    },
    async setPeriodReturn(rateList: { symbol: string, rate: number }[]) {
      rateList.forEach(({ symbol, rate }) => {
        const etf = this.etfDataList.find(el => el.symbol == symbol)
        if (etf) {
          etf.statistics.period_return = rate
        }
      })
    }
  },
})
