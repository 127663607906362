import en from "core/locale/en.json"
import ko from "core/locale/ko.json"

export const locale = import.meta.env.VITE_APP_LOCALE

const translate: {[index: string]: {[index: string]: string}} = locale == 'ko' ? ko : en

export function t(key: string) {
  const [sheet, index] = key.split('.')
  return translate[sheet][index]
}

export function n_month(n: number) {
  if (locale == 'ko') {
    return `${n} 개월`
  } else {
    if (n > 1) {
      return `${n} months`
    } else {
      return `${n} month`
    }
  }
}

export function dateFormatter(n: number) {
  if (locale == 'ko') {
    return `${n}일`
  } else {
    switch (n) {
      case 1:
        return '1st'
      case 2:
        return '2nd'
      case 3:
        return '3rd'
      default:
        return n + 'th'
    }
  }
}
