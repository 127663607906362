import type { RouteRecordRaw } from "vue-router"
import { createRouter, createWebHistory } from "vue-router"

const routes: Array<RouteRecordRaw> = [
  {
    path: '/', name: "Home", component: () => import("en/views/Home.vue"),
    meta: { title: 'Best Portfolio Backtesting Tool', description: "ETF Portfolio provides a portfolio analysis tool for backtesting tactical asset allocation and optimization. It allows you to evaluate historical performance and validate asset allocation strategies." }
  },
  {
    path: '/admin', name: "Admin", component: () => import("@/views/Admin.vue"),
    meta: { title: '관리자 페이지', authRequired: true }
  },
  {
    path: '/admin/plan', name: "AdminPlan", component: () => import("@/views/AdminPlan.vue"),
    meta: { mobileHeading: '구독 환불 페이지', authRequired: true }
  },
  {
    path: '/about-us', name: "Company", component: () => import("@/views/Company.vue"), 
    meta: { mobileHeading: 'About Us', title: 'About Us', description: 'In basketball, a "TripleDouble" refers to a player achieving double digits in three statistical categories, exemplifying versatility and all-around performance. This concept is equally applicable in business and investment. We aim to enhance multiple metrics, not just a single figure, by adhering to principles in planning, validation, and execution.' }
  },
  {
    path: '/pricing', name: "Pricing", component: () => import("@/views/Pricing.vue"), 
    meta: { mobileHeading: 'Pricing', title: 'Pricing', description: "Build your own portfolio using out backtesting tool with a few clicks. Start creating your own portfolio with ETF Portfolio today!" }
  },
  {
    path: '/user/profile', name: "UserPage", component: () => import("@/views/UserPage.vue"),
    meta: { mobileHeading: 'Account Info', authRequired: true }
  },
  {
    path: '/user/login', name: "UserLogin", component: () => import("en/views/Login.vue"),
    meta: { mobileHeading: 'Log in', }
  },
  {
    path: '/user/login/auth/:platform', name: "SocialLogin", component: () => import("en/views/SocialAuth.vue"), props: true,
  },
  {
    path: '/user/join', name: "UserJoin", component: () => import("en/views/Join.vue"),
    meta: { mobileHeading: 'Sign up', }
  },
  {
    path: '/user/dashboard', name: "UserDashboard", component: () => import("@/views/UserDashboard.vue"),
    meta: { mobileHeading: 'My Strategy', authRequired: true }
  },
  {
    path: '/user/etf', name: "UserEtf", component: () => import("@/views/UserEtf.vue"),
    meta: { mobileHeading: 'ETF Alert', authRequired: true }
  },
  {
    path: '/user/consent/mail', name: "UserConsentMail", component: () => import("@/views/MailConsent.vue")
  },
  {
    path: '/backtest/asset-allocation', name: "StaticBacktest", component: () => import("@/views/StaticRequest.vue"),
    meta: { mobileHeading: 'Asset Allocation', title: 'Asset Allocation Backtesting', description: "Explore the importance of backtesting your portfolio to enhance your investment performance. It applies pre-defined rules to historical market data to evaluate the effectiveness of the strategy.", }
  },
  {
    path: "/backtest/tactical-allocation", name: "DynamicBacktest", component: () => import("@/views/DynamicRequest.vue"),
    meta: { mobileHeading: 'Tactical Allocation', title: 'Tactical Allocation Backtesting', description: "Try our backtesting tool to evaluate the performance of a dynamic asset allocation strategy. Our tool flexibly adjusts asset selection and allocation weights over time in response to changes in the economic environment.", }
  },
  {
    path: "/backtest/combine-portfolios", name: "StrategyMix", component: () => import("@/views/StrategyMix.vue"),
    meta: { mobileHeading: 'Combine Portfolios', title: 'Multiple Portfolio Backtesting', description: "The Multiple Portfolio Backtesting is designed to help you mix and match different tactical asset allocation models to create new portfolios. You can create optimal 'model portfolios' based on portfolio optimization." }
  },
  {
    path: '/backtest/tactical-portfolio-allocation', name: "StrategyBacktest", component: () => import("@/views/StrategyRequest.vue"),
    meta: { mobileHeading: 'Tactical Portfolio Mix', title: 'Tactical Portfolio Backtesting', description: "Try our Multi-Portfolio Mix to evaluate the performance of a dynamic portfolio allocation strategy. Our tool flexibly adjusts portfolio selection and weights over time in response to changes in investor preferences.", }
  },
  {
    path: "/portfolio/:uuid", name: "PortfolioReport", component: () => import("@/views/PortfolioResult.vue"), props: true,
    meta: { mobileHeading: 'Performance', }
  },
  {
    path: "/strategy/gurus-portfolio", name: "StrategyIntroduction", component: () => import("@/views/StrategyIntroduction.vue"),
    meta: { mobileHeading: "Guru's TAA", title: 'List of Tactical Asset Allocation Strategies', description: "See how different tactical asset allocation strategies approach their portfolios and build your own. Follow in the footsteps of the gurus and easily build your own portfolios.", }
  },
  {
    path: "/strategy/screener", name: "Strategy", component: () => import("@/views/Strategy.vue"),
    meta: { mobileHeading: 'Screener', title: 'Tactical Portfolio Screener', description: "Filter and sort asset allocation strategies by key portfolio analysis statistics. Use the strategy comparison between your own and the guru's to improve your tactical asset allocation models. ", }
  },
  {
    path: "/strategy/:url", name: "StrategyResult", component: () => import("@/views/StrategyResult.vue"), props: true,
  },
  {
    path: "/strategy/compare", name: "StrategyCompare", component: () => import("@/views/CompareResultMobile.vue"), props: true,
    meta: { title: 'Strategy Comparison', description: 'Compare and select asset allocation strategies with a more than 20+ year horizon', }
  },
  {
    path: "/strategy/community-ideas/:market", name: "StrategyRanking", component: () => import("@/views/CommunityRanking.vue"), props: true,
    meta: { mobileHeading: 'Community Ideas', title: 'Community Portfolio Ideas', description: "Uncover the power of community ideas! ETF Portfolio provides tactical asset allocation strategy ideas from community members and allows you to replicate and refine your portfolios using backtesting."}
  },
  {
    path: "/etf/heatmap", name: "EtfDashboard", component: () => import("@/views/EtfDashboard.vue"),
    meta: { mobileHeading: 'ETF Heatmap', title: 'ETF Heatmap', description: "ETF Heatmap - ETF Portfolio offers a convenient way to gain exposure to a wide range of asset classes, including equities, bonds, and alternatives such as commodities and real estate. " }
  },
  {
    path: "/etf/screener", name: "EtfList", component: () => import("@/views/EtfList.vue"),
    meta: { mobileHeading: 'ETF Screener', title: 'ETF Screener', description: "Find out why ETF compariosn is key to building a strong investment portfolio. Our ETF Screener allows to analyze the performance and comparison of ETFs across various criteria such as asset class, style and industry. ", }
  },
  {
    path: "/etf/dividend", name: "EtfDividend", component: () => import("@/views/EtfDividend.vue"),
    meta: { mobileHeading: 'Dividend ETF', title: 'Dividend ETF', description: "Explore  top Dividend ETF! Find the right Dividend ETF in one place! Click to view and compare more details on Dividend ETFs including historical performance, dividends, holdings, expense ratios and more.", }
  },
  {
    path: "/etf/:ticker", name: "EtfDetail", component: () => import("@/views/EtfDetail.vue"),
    meta: {mobileHeading: 'ETF Detail' }, props: true,
  },
  {
    path: "/faqs", name: "FAQ", component: () => import("en/views/FAQ.vue"),
    meta: {mobileHeading: 'FAQs', title: 'Frequently Asked Questions', description: "A Step-by-Step Guide to backtesting and tactical asset allocation. Learn the dos and donts, benefits, pitfalls, and test your ideas with our FREE backtesting tool." },
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: () => import("@/views/NotFound.vue") },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    } else if (savedPosition) {
      return savedPosition
    } else if (to.name !== from.name) {
      return { top: 0 }
    }
  },
})

export default router
