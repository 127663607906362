<script setup lang="ts">
import { ContactMailAPI } from 'core/apis/backtest';
import { useUserStore } from 'core/stores/User';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import { inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const userStore = useUserStore()

const { t } = useI18n()

const showNotification = inject("showNotification") as Function

async function submit() {
  if (content.value.length == 0) return

  let userEmail = ''

  if (userStore.loggedInUser) {
    userEmail = userStore.loggedInUser.email
  } else {
    if (validateEmail(email.value)) {
      userEmail = email.value
      emailInvalid.value = false
    } else {
      emailInvalid.value = true
      return
    }
  }

  const response = await ContactMailAPI(userEmail, content.value)

  isDisplay.value = false
  showNotification('Success', t('popup.contact_04'))
}

const email = ref('')
const emailInvalid = ref(false)
const content = ref('')

function validateEmail(emailInput: string) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(emailInput);
}

const isDisplay = ref(false)

function show() {
  isDisplay.value = true
}

defineExpose({ show })

</script>
<template>
  <Dialog v-model:visible="isDisplay" :draggable="false" closable class="custom-dialog-user" modal>
    <template #header>
      <div class="dialog-header">{{ $t('popup.contact_01') }}</div>
    </template>
    <p class="dialog-des">{{ $t('popup.contact_02') }}</p>
    <InputText v-if="!userStore.loggedInUser" v-model="email" placeholder="Email" id="email-input" :class="{'invalid': emailInvalid}"/>
    <textarea :placeholder="$t('popup.contact_03')" v-model="content"></textarea>
    <div>
    </div>
    <template #footer>
      <div class="footer-btn-box">
        <button class="custom btn action-btn active" @click="submit">{{ $t('common.submit') }}</button>
      </div>
    </template>
  </Dialog>
</template>
<style lang="scss" scoped>
@import "@/style/button.scss";
@import "@/style/dialog.scss";

textarea {
  width: 100%;
  height: 304px;
  padding: 16px;
  outline: none;
  border: 1px solid #3952D7;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  resize: none;
}

#email-input {
  width: 100%;
  margin-bottom: 16px;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #3952D7;

  &.invalid {
    border-color: red;
  }
}
</style>