<script setup lang="ts">
import Dialog from 'primevue/dialog'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const display = ref(false)
const _type = ref("")
const _message = ref("")

const emits = defineEmits<{
  cancel: []
}>()

function showLock(type: string, message: string = 'GENERAL') {
  display.value = true
  _type.value = type
  _message.value = message
}

function closeNotification() {
  display.value = false
}

function planUpgrade() {
  display.value = false
  router.push('/pricing')
}

function goLogin() {
  display.value = false
  router.push('/user/login')
}

defineExpose({
  showLock, closeNotification
})

</script>
<template>
  <Dialog v-model:visible="display" style="min-width: 280px;" :draggable="false" class="custom-dialog"
    :closable="false" modal>
    <template #header>
      <div class="dialog-header">
        <img v-if="_type == 'GENERAL' || 'LOGIN'" src="https://cdn.etf-portfolio.com/image/icon/lock.svg" alt="lock icon" />
        <img v-else-if="_type == 'RANKING'" src="https://cdn.etf-portfolio.com/image/icon/trophy-lock.svg" alt="trophy and lock icon" />
        <img v-else-if="_type == 'DATA'" src="https://cdn.etf-portfolio.com/image/icon/database-lock.svg" alt="database and lock icon" />
      </div>
    </template>
    <div class="content">
      <p class="notification">{{ _message }}</p>
    </div>
    <template #footer>
      <div class="footer-btn-box">
        <button class="custom btn action-btn outline" @click="closeNotification" style="margin-right: 32px;">{{ $t('common.okay') }}</button>
        <button v-if="_type === 'LOGIN'" class="custom btn action-btn outline" @click="goLogin">{{ $t('common.login') }}</button>
        <button v-else class="custom btn action-btn outline" @click="planUpgrade">{{ $t('user.plan_upgrade') }}</button>
      </div>
    </template>
  </Dialog>
</template>
<style lang="scss">
@import "@/style/responsive.scss";

.custom-dialog {
  width: 440px;

  @include mobile {
    width: 343px;
  }
}
</style>
<style lang="scss" scoped>
@import "@/style/default.scss";

.custom-dialog {
  width: 440px;

  @include mobile {
    width: 343px;
  }

  .dialog-header {
    position: relative;
    width: 100%;

    img {
      position: absolute;
      top: 10px;
      left: calc(50% - 20px);
      margin: 0;
      width: 40px;
      height: 40px;
    }
  }

  .content {
    margin-top: 50px;
    height: 100%;


    .notification {
      font-size: 16px;
      line-height: 20px;
      color: $gray2;
      white-space: break-spaces;
      text-align: center;
    }
  }

  .footer-btn-box {
    display: flex;
    justify-content: center;

    button {
      font-size: 14px;
      width: 120px;
      margin: 0;
    }
  }
}
</style>