import { locale } from "core/service/LocaleService"

type holding = { name: string, weight: number }

export interface Asset {
  ord: number
  symbol: string
  exchange: string
  name: string
  market: string
  group: string
  category: string
  category2: string
  segment: string
  issuer: string
  inception: string
  start: string
  expense: number
  benchmark: string
  des: string
  weight: number
  statistics: any
  crawling_data: any
  holdings?: holding[]
  nick: string
  note: string
  futures: string
  region: string
  hedged: boolean
  dividend: any
  aum: number
  yield: number
  expansion: boolean
  ip: boolean
  rp: boolean
}

export interface AssetInput {
  ord: number
  symbol: string
  exchange: string
  name: string
  market: string
  category: string
  category2: string
  category3: string
  category4: string
  inception: string
  start: string
  issuer: string
  expense_ratio: number
  benchmark: string
  des: string
  statistics: any
  crawling_data: any
  holdings?: holding[]
  nick: string
  note: string
  futures: string
  region: string
  hedged: boolean
  dividend: any
  total_assets: number
  dividend_yield: number
  expansion: boolean
  ip: boolean
  rp: boolean
}

export class Asset implements Asset {
  constructor(data: AssetInput, weight = 0) {
    this.ord = data.ord
    this.exchange = data.exchange
    this.symbol = data.symbol
    this.name = data.name
    this.market = data.market
    this.group = data.category
    this.category = data.category2
    this.category2 = data.category3
    this.segment = data.category4
    this.issuer = data.issuer.replace(/( Corp| Inc| Ltd|운용)$/,'')
    this.inception = data.inception
    this.start = data.start
    this.expense = data.expense_ratio
    this.benchmark = data.benchmark
    this.des = data.des
    this.statistics = data.statistics
    this.crawling_data = data.crawling_data
    if (data.holdings) {
      this.holdings = data.holdings.map((el: holding) => {
        return {
          name: el.name.replace(/\(dup\)|\(ca\)/g, '').trim(),
          weight: el.weight
        }
      })
    }
    this.nick = data.nick
    this.weight = weight * 100
    this.futures = data.futures
    this.region = data.region
    this.hedged = data.hedged
    this.dividend = data.dividend
    this.note = data.note
    this.aum = data.total_assets
    this.yield = data.dividend_yield
    this.expansion = data.expansion
    this.ip = data.ip
    this.rp = data.rp
  }
}

export function sortAsset(arr?: Asset[]) {
  const equity = locale == 'en' ? 'equities' : '주식'
  const bond = locale == 'en' ? 'bonds' : '채권'
  const alternative = locale == 'en' ? 'alternatives' : '대체자산'

  return [
    { ko: '주식', en: 'Equities', code: 'E', assets: arr ? arr.filter((asset) => asset.group.toLowerCase().includes(equity)) : [] },
    { ko: '채권', en: 'Bonds', code: 'B', assets: arr ? arr.filter((asset) => asset.group.toLowerCase().includes(bond)) : [] },
    { ko: '대체자산', en: 'Alternatives', code: 'A', assets: arr ? arr.filter((asset) => asset.group.toLowerCase().includes(alternative)) : [] },
  ]
}
