<script setup lang="ts">
import { useUserStore } from 'core/stores/User'
import { format } from "date-fns"
import { ref } from 'vue'
import { useI18n } from "vue-i18n"

const userStore = useUserStore()

const display = ref(false)
const userNotificationWrapper = ref()

function handleOutsideClick(e: Event) {
  e.stopPropagation()
  if (!userNotificationWrapper.value.contains(e.target)) {
    display.value = false
    userStore.updateUnreadNotification()

    document.removeEventListener('click', handleOutsideClick)
    document.removeEventListener('touchstart', handleOutsideClick)
  }
}

function show() {
  if (display.value) {
    handleOutsideClick(new MouseEvent(''))
  } else {
    display.value = true
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)
  }
}

const { locale } = useI18n()

function createAt(date: string) {
  const d = new Date(date)
  return format(d, locale.value == 'ko' ? 'M월 dd일' : 'MMM dd')
}

</script>
<template>
  <div v-if="userStore.loggedInUser" class="user-notification" ref="userNotificationWrapper">
    <svg @click="show" class="btn" width="32" height="32" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path v-if="display"
        d="M5 19C4.71667 19 4.479 18.904 4.287 18.712C4.095 18.52 3.99934 18.2827 4 18C4 17.7167 4.096 17.479 4.288 17.287C4.48 17.095 4.71734 16.9993 5 17H6V10C6 8.61667 6.41667 7.38734 7.25 6.312C8.08334 5.23667 9.16667 4.53267 10.5 4.2V3.5C10.5 3.08334 10.646 2.729 10.938 2.437C11.23 2.145 11.584 1.99934 12 2C12.4167 2 12.771 2.146 13.063 2.438C13.355 2.73 13.5007 3.084 13.5 3.5V4.2C14.8333 4.53334 15.9167 5.23767 16.75 6.313C17.5833 7.38834 18 8.61734 18 10V17H19C19.2833 17 19.521 17.096 19.713 17.288C19.905 17.48 20.0007 17.7173 20 18C20 18.2833 19.904 18.521 19.712 18.713C19.52 18.905 19.2827 19.0007 19 19H5ZM12 22C11.45 22 10.979 21.804 10.587 21.412C10.195 21.02 9.99934 20.5493 10 20H14C14 20.55 13.804 21.021 13.412 21.413C13.02 21.805 12.5493 22.0007 12 22Z"
        fill="#3952D7" />
      <path v-else
        d="M12 21.75C13.1 21.75 14 20.85 14 19.75H10C10 20.85 10.9 21.75 12 21.75ZM18 15.75V10.75C18 7.68 16.37 5.11 13.5 4.43V3.75C13.5 2.92 12.83 2.25 12 2.25C11.17 2.25 10.5 2.92 10.5 3.75V4.43C7.64 5.11 6 7.67 6 10.75V15.75L4 17.75V18.75H20V17.75L18 15.75ZM16 16.75H8V10.75C8 8.27 9.51 6.25 12 6.25C14.49 6.25 16 8.27 16 10.75V16.75Z"
        fill="#5E5E5E" />
    </svg>
    <span v-if="userStore.unreadNotificationCount" class="count">{{ userStore.unreadNotificationCount }}</span>
    <div class="overlay" :class="{ 'open': display }" @click="display = false">
      <p class="panel-header">{{ $t('user.notification_01') }}</p>
      <ul class="block scroll-container">
        <li v-for="notification in userStore.userNotificationList">
          <RouterLink :to="notification.url">
            <div>
              <p class="title">{{ notification.title }}</p>
              <p class="content">{{ notification.content }}</p>
            </div>
            <div style="display: flex;" class="send-date">
              <img src="https://cdn.etf-portfolio.com/image/icon/check.svg" alt="check icon" width="16" height="16" />&nbsp;{{ createAt(notification.created_at) }}
            </div>
          </RouterLink>
        </li>
        <div v-if="userStore.userNotificationList.length == 0" class="no-data">
          {{ $t('common.empty') }}
        </div>
      </ul>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "@/style/default.scss";

.no-data {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

p.panel-header {
  margin: 0;
  padding: 16px;
  font-size: 16px;
  font-weight: 600;
  color: $black7;
  border-bottom: 1px solid #d9d9d9;
}

.count {
  width: 16px;
  height: 16px;
  position: absolute;
  top: -2px;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $red5;
  color: white;
  font-size: 10px;
  border-radius: 8px;
  padding: 2.5px 5px;
}

.user-notification {
  position: relative;
  height: 32px;
  width: 32px;

  .overlay {
    display: none;
    width: fit-content;
    position: absolute;
    top: 70px;
    right: 0;
    z-index: 10;
    background-color: #FFF;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08));
    border-radius: 8px;
  }

  .overlay.open {
    display: block;
  }
}

@include mobile {
  .user-notification {
    margin-right: 12px;

    .overlay {
      position: fixed;
      display: block;
      z-index: 223;
      transition: top 0.4s ease;
      right: 16px;

      &:not(.open) {
        top: -100vh;
      }
    }
  }
}

ul {
  width: 360px;
  margin: 0 0 16px;
  padding: 0;
  list-style: none;
  max-height: 60vh;

  @include mobile {
    width: calc(100vw - 32px);
  }

  font-size: 14px;

  li {
    padding: 16px;
    justify-content: space-between;
    color: $black7;

    .title {
      font-size: 16px;
      font-weight: 700;
      line-height: 18px;
    }

    .content {
      margin-top: 4px;
      font-size: 14px;
      line-height: 18px;
      text-align: left;
    }

    .send-date {
      margin-top: 8px;
      min-width: 60px;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  @include desktop {
    li:hover {
      background-color: #FAFBFF;
    }
  }
}
</style>