export interface UserResponse {
  nickname: string
  country?: string
  uuid: string
  email: string
  phone?: string
  sns?: string
  first_name: string
  last_name: string
  profile_thumbnail: string
  plan: string
  plan_period: string
  marketing_consent: boolean
  newsletter_consent: boolean
  strategy_count: number
  backtest_count: number
  backtest_pass_count: number
  ai_summary_count: number
  etf_alarm_count: number
  alarm: any
  have_backtest_pass: boolean
}

export interface User {
  nickname: string
  country?: string
  uuid: string
  email: string
  phone?: string
  sns?: string
  firstName: string
  lastName: string
  profile_thumbnail: string
  plan: string
  planPeriod: string
  marketingConsent: boolean
  newsletterConsent: boolean
  strategyCount: number
  backtestCount: number
  backtestPassCount: number
  aiSummaryCount: number
  etfAlarmCount: number
  alarm: any
  haveBacktestPass: boolean
}

export class User implements User {
  constructor(user: UserResponse) {
    this.nickname = user.nickname
    this.country = user.country
    this.uuid = user.uuid
    this.email = user.email
    this.phone = user.phone
    this.sns = user.sns
    this.firstName = user.first_name
    this.lastName = user.last_name
    this.profile_thumbnail = user.profile_thumbnail
    this.plan = user.plan
    this.planPeriod = user.plan_period
    this.marketingConsent = user.marketing_consent
    this.newsletterConsent = user.newsletter_consent
    this.strategyCount = user.strategy_count
    this.backtestCount = user.backtest_count
    this.aiSummaryCount = user.ai_summary_count
    this.backtestPassCount = user.backtest_pass_count
    this.etfAlarmCount = user.etf_alarm_count
    this.alarm = user.alarm
    this.haveBacktestPass = user.have_backtest_pass
  }
}
