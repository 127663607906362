<script setup lang="ts">
import ContactDialog from '@/components/dialog/ContactDialog.vue'
import UserInfo from "@/components/user/UserInfo.vue"
import UserNotification from "@/components/user/UserNotification.vue"
import { usePathStore, useUserStore } from "core/stores/User"
import { autoComplete, useMobileCheck } from "core/util"
import AutoComplete from 'primevue/autocomplete'
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const [filterKeyword, suggestions, search, moveEtfPage] = autoComplete()

enum Navigation {
  BACKTEST = "BACKTEST",
  STRATEGY = "STRATEGY",
  ETF = "ETF",
  GUIDE = "GUIDE",
  NONE = "NONE",
  PRICING = "PRICING"
}

const displayMenu = ref()
const selectedNav = ref(Navigation.NONE)
const showBottom = ref(false)

const userStore = useUserStore()
const pathStore = usePathStore()

const isMobile = useMobileCheck()

watch(isMobile, () => {
  if (!isMobile.value) displayMenu.value = undefined
})

watch(route, (r: any) => {
  displayMenu.value = false
})

const contactDialog = ref()

const mobileHeading = computed(() => {
  if (route.name == 'EtfDetail') {
    return route.path.split('/')[2]
  } else if (route.name == 'StrategyResult') {
    return pathStore.mobileHeadingOfStrategy
  } else {
    return route.meta.mobileHeading
  }
})

</script>
<template>
  <header @touchmove.prevent.stop="">
    <div id="top-bar" :class="{ 'open': displayMenu }">
      <section>
        <div class="logo-box" style="min-width: 88px;">
          <div class="hide-desktop">
            <img v-if="!displayMenu" class="hide-desktop" src="https://cdn.etf-portfolio.com/image/icon/hamburger.svg"
              alt="mobile menu icon" @click="displayMenu = 'menu';" width="24" height="24" />
            <img v-if="displayMenu" class="hide-desktop" src="https://cdn.etf-portfolio.com/image/icon/close.svg"
              alt="mobile menu close icon" @click="displayMenu = undefined; selectedNav = Navigation.NONE" width="24"
              height="24" />
          </div>
          <router-link to="/">
            <img v-if="isMobile && mobileHeading" class="hide-desktop" src="/logo-blue-24px.svg" alt="logo image"
              width="36" height="36" style="margin-top: 4px;">
            <img v-else src="/logo.svg" alt="logo image" width="225" height="36" style="margin-top: 4px;">
          </router-link>
        </div>
        <h1 class="hide-desktop" :class="{ long: String(mobileHeading).length > 12 }">{{ mobileHeading }}
        </h1>
        <div class="btn hide-desktop">
          <RouterLink v-if="!userStore.loggedInUser" to="/user/login" style="height: 32px;">
            <img src="https://cdn.etf-portfolio.com/image/icon-3d/account.svg" alt="user menu icon"
              width="32" height="32" />
          </RouterLink>
          <UserNotification v-if="!displayMenu" />
          <UserInfo v-if="!displayMenu" />
        </div>
        <div id="navigation" class="hide-mobile" style="margin-left: 20px;">
          <div @mouseenter="showBottom = true" @mouseleave="showBottom = false">
            <span class="btn" :class="{ selected: route.path.includes('strategy') }"
              @mouseover="selectedNav = Navigation.STRATEGY">Strategy</span>
            <span class="btn" :class="{ selected: route.path.includes('backtest') }"
              @mouseover="selectedNav = Navigation.BACKTEST">Backtest</span>
            <span class="btn" :class="{ selected: route.path.includes('etf') }"
              @mouseover="selectedNav = Navigation.ETF">ETF</span>
            <RouterLink class="btn" :class="{ selected: route.path.includes('pricing') }" to="/pricing"
              @mouseover="selectedNav = Navigation.PRICING">Pricing</RouterLink>
            <a href="https://docs.etf-portfolio.com/" target="_blank" rel="noopener">Guide</a>
          </div>
        </div>
        <div class="p-input-icon-left top-search-input-wrapper hide-mobile">
          <AutoComplete inputClass="search-input" placeholder="ETF Search" v-model="filterKeyword"
            :suggestions="suggestions" @complete="search" @item-select="moveEtfPage"
            :panelStyle="{ 'font-size': '12px', 'width': '290px' }" />
          <i class="pi pi-search" style="top: 47%;"></i>
        </div>
        <div class="top-right hide-mobile">
          <template v-if="userStore.loggedInUser">
            <UserNotification />
            <p class="middle-bar" style="margin: 0;">&nbsp&nbsp|&nbsp&nbsp</p>
            <UserInfo />
          </template>
          <template v-else>
            <router-link class="btn login-button" to="/user/login">Log in</router-link>
            <p class="middle-bar" style="margin: 0;">&nbsp&nbsp|&nbsp&nbsp</p>
            <router-link class="btn sign-up" to="/user/join">Sign up</router-link>
          </template>
        </div>
      </section>
      <div id="navigation-mobile" class="hide-desktop" :class="{ 'open': displayMenu == 'menu' }">
        <div class="p-input-icon-left search-input-wrapper">
          <AutoComplete inputClass="search-input" placeholder="ETF Search" v-model="filterKeyword"
            :suggestions="suggestions" @complete="search" @item-select="moveEtfPage"
            :panelStyle="{ 'font-size': '12px', 'width': '290px' }" />
          <i class="pi pi-search" style="top: 47%;"></i>
        </div>
        <ul>
          <li class="btn" @click.stop="selectedNav = Navigation.STRATEGY"
            :class="{ active: selectedNav == Navigation.STRATEGY, selected: route.path.includes('strategy') }">Strategy
          </li>
          <div class="navigation-child-mobile" v-if="selectedNav == Navigation.STRATEGY">
            <router-link class="btn" :class="{ selected: route.name == 'StrategyIntroduction' }"
              to="/strategy/gurus-portfolio">Guru's TAA
            </router-link>
            <router-link class="btn" :class="{ selected: route.name == 'Strategy' }"
              to="/strategy/screener">Screener</router-link>
            <router-link class="btn" :class="{ selected: route.name == 'StrategyRanking' }"
              to="/strategy/community-ideas/global">Community
              Ideas</router-link>
          </div>
          <li class="btn" @click.stop="selectedNav = Navigation.BACKTEST"
            :class="{ active: selectedNav == Navigation.BACKTEST, selected: route.path.includes('backtest') }">Backtest
          </li>
          <div class="navigation-child-mobile" v-if="selectedNav == Navigation.BACKTEST">
            <router-link class="btn" :class="{ selected: route.name == 'StaticBacktest' }"
              to="/backtest/asset-allocation">Asset
              Allocation
            </router-link>
            <router-link class="btn" :class="{ selected: route.name == 'DynamicBacktest' }"
              to="/backtest/tactical-allocation">Tactical
              Allocation
            </router-link>
            <router-link class="btn" :class="{ selected: route.name == 'StrategyMix' }"
              to="/backtest/combine-portfolios">Combine
              Portfolios</router-link>
            <router-link class="btn" :class="{ selected: route.name == 'StrategyBacktest' }"
              to="/backtest/tactical-portfolio-allocation">Tactical
              Portfolio Mix</router-link>
          </div>
          <li class="btn" @click.stop="selectedNav = Navigation.ETF"
            :class="{ active: selectedNav == Navigation.ETF, selected: route.path.includes('etf') }">
            ETF</li>
          <div class="navigation-child-mobile" v-if="selectedNav == Navigation.ETF">
            <router-link class="btn" :class="{ selected: route.name == 'EtfDashboard' }" to="/etf/heatmap">
              Heatmap</router-link>
            <router-link class="btn" :class="{ selected: route.name == 'EtfList' }" to="/etf/screener">
              Screener</router-link>
            <router-link class="btn" :class="{ selected: route.name == 'EtfDividend' }" to="/etf/dividend">
              Dividend ETF</router-link>
          </div>
          <li>
            <a href="https://docs.etf-portfolio.com/" target="_blank" rel="noopener" class="btn">Guide</a>
          </li>
          <li class="btn" @click.stop="selectedNav = Navigation.PRICING"
            :class="{ active: selectedNav == Navigation.PRICING, selected: route.path.includes('pricing') }">
            <RouterLink to="/pricing">Pricing</RouterLink>
          </li>
        </ul>
        <div class="top-right">
          <template v-if="!userStore.loggedInUser">
            <router-link class="btn login-button" to="/user/login">Log in</router-link>
            <p class="middle-bar">&nbsp&nbsp|&nbsp&nbsp</p>
            <router-link class="btn sign-up" to="/user/join">Sign up</router-link>
          </template>
        </div>
        <div class="short-cut">
          <div>
            <a @click="contactDialog.show"><img src="https://cdn.etf-portfolio.com/image/icon/email-gray.svg"
                alt="email icon" width="36" height="36" /></a>
          </div>
        </div>
      </div>
    </div>
    <div id="navigation-bottom" v-show="showBottom" @mouseenter="showBottom = true" @mouseleave="showBottom = false">
      <div class="navigation-child" v-show="selectedNav == Navigation.STRATEGY" style="margin-left: -360px;">
        <router-link class="btn" :class="{ selected: route.name == 'StrategyIntroduction' }"
          to="/strategy/gurus-portfolio">Guru's TAA
        </router-link>
        <router-link class="btn" :class="{ selected: route.name == 'Strategy' }"
          to="/strategy/screener">Screener</router-link>
        <router-link class="btn" :class="{ selected: route.name == 'StrategyRanking' }"
          to="/strategy/community-ideas/global">Community
          Ideas</router-link>
      </div>
      <div class="navigation-child" v-show="selectedNav == Navigation.BACKTEST" style="margin-left: -160px;">
        <router-link class="btn" :class="{ selected: route.name == 'StaticBacktest' }"
          to="/backtest/asset-allocation">Asset
          Allocation</router-link>
        <router-link class="btn" :class="{ selected: route.name == 'DynamicBacktest' }"
          to="/backtest/tactical-allocation">Tactical
          Allocation</router-link>
        <router-link class="btn" :class="{ selected: route.name == 'StrategyMix' }"
          to="/backtest/combine-portfolios">Combine
          Portfolios</router-link>
        <router-link class="btn" :class="{ selected: route.name == 'StrategyBacktest' }"
          to="/backtest/tactical-portfolio-allocation">Tactical
          Portfolio Mix</router-link>
      </div>
      <div class="navigation-child" v-show="selectedNav == Navigation.ETF" style="margin-left: -20px;">
        <router-link class="btn" :class="{ selected: route.name == 'EtfDashboard' }" to="/etf/heatmap">
          Heatmap</router-link>
        <router-link class="btn" :class="{ selected: route.name == 'EtfList' }" to="/etf/screener">
          Screener</router-link>
        <router-link class="btn" :class="{ selected: route.name == 'EtfDividend' }" to="/etf/dividend">
          Dividend ETF</router-link>
      </div>
    </div>
    <ContactDialog ref="contactDialog" />
  </header>
</template>
<style lang="scss">
@import "@/style/color.scss";

.top-search-input-wrapper.hide-mobile {
  margin: 0;
  width: 150px;
  height: 40px;
  border: 1px solid $gray3;
  border-radius: 16px;
  overflow: hidden;

  .search-input {
    width: 110px !important;
    height: 100%;
    font-size: 14px;
    padding: 5.4px 8px 5.4px 16px;
    border-style: none;
    background-color: transparent;
  }

  .pi-search {
    font-size: 20px;
    left: auto !important;
    color: $gray2 !important;
    right: 20px;
  }
}

#navigation-mobile {
  .search-input-wrapper {
    width: calc(100% - 32px);
    height: 48px;
    margin: 32px 16px 0;
    border: 1px solid $gray3;
    border-radius: 12px;

    .search-input {
      border: none;
      padding: 8px 16px;
    }
  }
}
</style>
<style lang="scss" scoped>
@import "@/style/default.scss";

a.btn,
span.btn {
  img {
    filter: invert(48%) sepia(20%) saturate(266%) hue-rotate(176deg) brightness(91%) contrast(89%);
  }

  &.selected,
  &:hover {
    color: $primary !important;

    img {
      filter: none;
    }
  }
}

header {
  position: fixed;
  top: auto;
  left: 0;
  z-index: 222;
  width: 100%;

  @include mobile {
    position: relative;
    height: 0;
  }
}

#top-bar {
  width: 100%;
  border-bottom: #e0e0e0 1px solid;
  display: flex;
  justify-content: center;
  background-color: white;

  @include mobile {
    display: block;
    border: none;
  }

  h1 {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 16px;
    height: auto;
    box-shadow: none;
    color: $black9;
  }

  h1.long {
    font-size: 17px;
  }
}

.logo-box {
  display: flex;
  align-items: center;

  div.hide-desktop {
    height: 56px;
    padding: 16px 12px;
  }

  @include mobile {
    width: 104px;
    margin: 0;
  }
}

section {
  z-index: 224;
  max-width: 1040px;
  min-width: 1040px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  background-color: white;

  @include mobile {
    position: fixed;
    min-width: auto;
    height: 56px;
    border-bottom: 1px solid #e0e0e0;
  }
}

#navigation {
  font-weight: 500;
  font-size: 18px;
  line-height: 80px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;
  color: $black7;

  span,
  a:not(:last-child) {
    margin-right: 32px;
  }
}

#navigation-bottom {
  display: flex;
  justify-content: center;
  background-color: white;
}

.navigation-child {
  display: flex;

  .btn {
    padding: 0 20px;
    vertical-align: top;
    text-align: center;

    font-size: 16px;
    line-height: 56px;
    letter-spacing: -0.03em;
    color: $black7;

    &:hover,
    &.selected {
      font-weight: 500;
      border-bottom: 2px solid $primary;
    }
  }
}

.middle-bar {
  margin: 0 5px 0;
  cursor: default;
  color: $gray5 !important;
  transform: scale(1, 0.9);
}

.top-right {
  display: flex;
  justify-content: center;
  align-items: center;

  @include desktop {
    justify-content: flex-end;
    width: 110px;
    min-width: 110px;
  }

  .btn {
    font-size: 14px;
    text-align: center;
    letter-spacing: -0.025em;
    color: $gray2;
  }
}

.short-cut {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  div {
    display: flex;
    justify-content: space-between;
  }
}

section div.btn.hide-desktop {
  width: 100px;
  height: 56px;
  padding: 16px 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#navigation-mobile {
  z-index: 223;

  position: fixed;
  width: 100%;
  height: calc(100vh - 56px);
  top: min(-120vh, -700px);
  background: white;

  @include desktop {
    display: none;
  }

  transition: top 0.4s ease;

  &.open {
    top: 56px;
  }

  ul {
    padding: 0;
    margin: 16px;
    height: fit-content;
    width: auto;
    border-radius: 8px;

    li {
      padding: 0px 16px;
      list-style: none;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $black1;
      font-weight: 500;
      font-size: 16px;
      line-height: 48px;
      letter-spacing: -0.03em;
      color: $black6;
    }

    li.active,
    li.selected {
      color: $primary6;
    }
  }

  .gray {
    filter: grayscale(1);
  }
}

.navigation-child-mobile {
  display: flex;
  flex-direction: column;
  font-size: 16px;

  a {
    margin: 16px 0 16px 30px;
    line-height: 20px;
    font-size: 15px;
    letter-spacing: -0.03em;
    color: $black6;
  }
}
</style>
