

  async function getBanner() {
    try {
      const response = await fetch('https://api.etf-portfolio.com/public/data/banner/latest?lang=' + import.meta.env.VITE_APP_LOCALE);
      const data = await response.json();

      if (data.content.length == 0) {
        return;
      }

      const banner = JSON.parse(localStorage.getItem("banner"));
      if (banner && banner.no == data.no) {
        if (banner.close) {
          return;
        }
      } else {
        localStorage.setItem("banner", JSON.stringify({ text: data.content, no: data.no, close: false }));
      }
      document.querySelector('#banner-text').textContent = data.content;
      document.querySelector('#banner').classList.add('show');

      if (data.link) {
        document.querySelector('#banner-link').href = data.link;
        document.querySelector('#banner-link').classList.add('show');
      }

    } catch (error) {
      console.error(error);
      document.querySelector('#banner').classList.remove('show');
    }
  }

  getBanner()

  function closeBanner() {
    const banner = JSON.parse(localStorage.getItem("banner"));
    banner.close = true;
    localStorage.setItem("banner", JSON.stringify(banner));
    document.querySelector('#banner').classList.remove('show');
  }

