<script setup lang="ts">
import { ref } from 'vue';
import ContactDialog from '@/components/dialog/ContactDialog.vue'

const contactDialog = ref()

</script>
<template>
  <section>
    <div id="sitemap">
      <div class="service-logo">
        <img src="/logo.svg" alt="logo image" width="225" height="36" />
      </div>
      <div class="col-sec">
        <div class="col-sec-head">STRATEGY</div>
        <RouterLink to="/strategy/gurus-portfolio">Guru's TAA</RouterLink>
        <RouterLink to="/strategy/screener">Screener</RouterLink>
        <RouterLink to="/strategy/community-ideas">Community Ideas</RouterLink>
      </div>
      <div class="col-sec">
        <div class="col-sec-head">BACKTEST</div>
        <RouterLink to="/backtest/asset-allocation">Asset Allocation</RouterLink>
        <RouterLink to="/backtest/tactical-allocation">Tactical Allocation</RouterLink>
        <RouterLink to="/backtest/combine-portfolios">Combine Portfolios</RouterLink>
        <RouterLink to="/backtest/tactical-portfolio-allocation">Tactical Portfolio Mix</RouterLink>
      </div>
      <div class="col-sec">
        <div class="col-sec-head">ETF</div>
        <RouterLink to="/etf/heatmap">Heatmap</RouterLink>
        <RouterLink to="/etf/screener">Screener</RouterLink>
        <RouterLink to="/etf/dividend">Dividend ETF</RouterLink>
      </div>
      <div class="col-sec">
        <div class="col-sec-head">COMPANY</div>
        <a href="https://docs.etf-portfolio.com/" target="_blank" rel="noopener">Guide</a>
        <RouterLink to="/faqs">FAQs</RouterLink>
        <RouterLink to="/pricing">Pricing</RouterLink>
        <RouterLink to="/about-us">About Us</RouterLink>
      </div>
    </div>
    <div id="information">
      <p id="copyright">ⓒ 2024 TripleDouble. All Rights Reserved.</p><a
        href="https://etf-portfolio.notion.site/Terms-of-Services-6c20c4b1afcb443db7281c386d403f96" target="_blank" rel="noopener">Term of Use</a> <span
        class="split">|</span> <a href="https://etf-portfolio.notion.site/Privacy-Policy-9c689edd839542c8b60bfd3aae1218c3"
        target="_blank" rel="noopener">Privacy Policy</a> <span class="split">|</span> <a style="cursor: pointer;" @click="contactDialog.show">Contact Us</a>
    </div>
    <ContactDialog ref="contactDialog" />
  </section>
</template>
<style lang="scss" scoped>
@import "@/style/default.scss";

.split {
  display: inline-block;
  transform: scale(0.8);
}

section {
  width: 988px;
  max-width: 988px;
  padding: 60px 0 32px;

  font-size: 13px;
  letter-spacing: -0.03em;
  color: $black6;
}

#sitemap {
  display: flex;
  width: 100%;
  font-size: 14px;

  @include mobile {
    flex-direction: column;
    gap: 32px;
  }

  div.service-logo {
    min-width: 300px;
  }

  div.col-sec {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;

    .col-sec-head {
      font-size: 16px;
      font-weight: 500;
      color: $black7;
      line-height: 24px;
    }

    a {
      display: block;
    }
  }
}

#information {
  margin-top: 32px;
  padding-top: 32px;
  display: flex;
  align-items: center;
  border-top: 1px solid lightgray;

  a {
    font-weight: 400;
    line-height: 20px;
    padding: 0 16px;
  }

  #copyright {
    font-weight: 400;
    line-height: 20px;
    margin-right: 30px;
    padding: 0;

    @include mobile {
      width: 100%;
    }
  }

  @include mobile {
    flex-wrap: wrap;

    a:nth-child(2) {
      padding: 0 16px 0 0;
    }
  }
}
</style>
