import type { AutoCompleteCompleteEvent, AutoCompleteItemSelectEvent } from 'primevue/autocomplete'
import { computed, onMounted, onUnmounted, ref, type Ref } from 'vue'
import { useEtfStore } from './stores/Etf'
import format from 'date-fns/format'
import { locale, t } from './service/LocaleService'
import { useRouter } from 'vue-router'

export const DOMAIN = import.meta.env.VITE_DOMAIN

export function toPercent(value?: number | null | string, n = 1) {
  if (value && typeof value === "number") {
    return (value * 100).toFixed(n) + '%'
  } else if (value == 0) {
    return '0%'
  } else {
    return '-'
  }
}

export function toFix(value?: number | null, n = 2, currency: string | undefined = undefined) {
  if (value && typeof value === "number") {
    return (currency ? currency + ' ' : '') + value.toFixed(n).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } else if (value === 0) {
    return '-'
  } else {
    return '-'
  }
}

export function toBool(value?: boolean | null, positiveValue = '긍정', negativeValue = '부정') {
  if (value === true) {
    return positiveValue
  } else if (value === false) {
    return negativeValue
  } else {
    return '-'
  }
}

export function toLocalePrice(price: number, isUs: boolean) {
  return (isUs ? '$' : "\u{20A9}") + (isUs ? price : Math.round(price)).toLocaleString()
}

export function toLocaleNumber(num: number, exchange: string, short: boolean = false) {
  const unit = exchange == 'USD' ? t('common.dollars') : '원'
  if (locale == 'en') {
    return `$${num.toLocaleString()}`
  }

  if (num >= 0) {
    const dddd = Math.floor(num / 1000000000000)
    const aaaa = Math.floor((num % 1000000000000) / 100000000)
    const bbbb = Math.floor((num % 100000000) / 10000)
    const cccc = (num % 10000)
    return `${dddd ? dddd : ''}${dddd ? '조' : ''} ${aaaa ? aaaa : ''}${aaaa ? '억' : ''} ${bbbb ? bbbb : ''}${bbbb ? '만' : ''} ${cccc && !short ? cccc : ''} ${unit}`
  } else {
    const abs = -num
    const dddd = Math.floor(abs / 1000000000000)
    const aaaa = Math.floor((abs % 1000000000000) / 100000000)
    const bbbb = Math.floor((abs % 100000000) / 10000)
    const cccc = (abs % 10000)
    return `-${dddd ? dddd : ''}${dddd ? '조' : ''} ${aaaa ? aaaa : ''}${aaaa ? '억' : ''} ${bbbb ? bbbb : ''}${bbbb ? '만' : ''} ${cccc && !short ? cccc : ''} ${unit}`
  }
}

export function dateToString(date: Date | string, isString = false) {
  if (typeof date == 'string') {
    date = new Date(date)
  }
  const stringFmt = locale == 'ko' ? 'yyyy년 MM월 dd일' : 'MMM dd yyyy'
  return format(date, isString ? stringFmt : 'yyyy/MM')
}

export function dateToYYYYMMDD(date: Date | string) {
  if (typeof date == 'string') {
    date = new Date(date)
  }
  return format(date, 'yyyy-MM-dd')
}

export function checkEmailFormat(email: string) {
  return /^\w+([+._-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(email)
}

export function checkPasswordFormat(password: string) {
  return /^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/.test(password)
}


export function randomCode() {
  return Math.floor(Math.random() * 10 ** 10).toString(36)
}

export function randomNumCode(digit = 6) {
  const code = (Math.random() * 10 ** digit).toFixed()
  return code.length < 6 ? '0' + code : code
}

export function numberList(n: number, multiple = 1, suffix = '') {
  if (n <= 0) return []
  return new Array(n).fill(0).map((_, i) => {
    i = (i + 1) * multiple
    return { name: i.toString() + suffix, code: i }
  })
}

export function monthList(n: number) {
  if (n <= 0) return []
  return new Array(n).fill(0).map((_, i) => {
    i = (i + 1)
    let suffix = ' 개월'
    if (locale == 'en') {
      suffix = i == 1 ? ' month' : ' months'
    }
    return { name: i.toString() + suffix, code: i }
  })
}

export function returnMatchColor(ret: number, mul: number = 1) {
  if (isNaN(ret)) {
    return 'transparent'
  } else if (ret >= 0.01 * mul) {
    return "#599365"
  } else if (ret >= 0.007 * mul) {
    return "#83AE8C"
  } else if (ret >= 0.004 * mul) {
    return "#ACC9B2"
  } else if (ret >= 0.001 * mul) {
    return "#D6E4D9"
  } else if (ret > -0.001 * mul) {
    return "#E1E1E4"   // 0 근처일 때
  } else if (ret > -0.004 * mul) {
    return "#f6d4d5"
  } else if (ret > -0.007 * mul) {
    return "#edaaab"
  } else if (ret > -0.01 * mul) {
    return "#e57f81"
  } else {
    return "#CE6264"
  }
}

export function useMobileCheck() {
  const windowInnerWidth = ref(window.innerWidth)

  function updateWindowWidth() {
    windowInnerWidth.value = window.innerWidth
  }

  onMounted(() => window.addEventListener('resize', updateWindowWidth))
  onUnmounted(() => window.removeEventListener('resize', updateWindowWidth))

  return computed(() => {
    return windowInnerWidth.value < 988
  })
}

export function setMetaTag(title?: string, description?: string) {
  if (title) {
    (document.querySelector('head meta[name="title"]') as any).content = title
    document.title = title
  }
  if (description) {
    (document.querySelector('head meta[name="description"]') as any).content = description
  }
}

export function setCanonicalMetaTag(url: string) {
  const canonicalURL = `https://${DOMAIN}.com${url}`
  const existLinkTag = document.querySelector('link[rel="canonical"]')
  if (existLinkTag) {
    (existLinkTag as any).href = canonicalURL
  } else {
    const linkTag = document.createElement('link')
    linkTag.setAttribute('rel', 'canonical')
    linkTag.href = canonicalURL
    document.head.appendChild(linkTag)
  }
}

export function removeCanonicalMetaTag() {
  const linkTag = document.querySelector('link[rel="canonical"]')
  if (linkTag) document.head.removeChild(linkTag)
}

export function adjustDecimal(num: number, digit = 2) {
  return Math.round(num * 10 ** digit) / 10 ** digit || 0
}

export function getProfileImgURL(user: { nick: string, profileImg: string }) {
  if (user.nick) {
    return new URL(user.profileImg, import.meta.url).href
  } else {
    return `https://cdn.etf-portfolio.com/image/profile/${user.profileImg}`
  }
}

export function getRankImgURL(rank: number) {
  switch (rank) {
    case 1:
      return `https://cdn.etf-portfolio.com/image/icon-3d/trophy1.svg`
    case 2:
      return `https://cdn.etf-portfolio.com/image/icon-3d/trophy2.svg`
    default:
      return `https://cdn.etf-portfolio.com/image/icon-3d/trophy3.svg`
  }
}

export function setAltProfileImg(event: Event) {
  if (event.target) {
    (event.target as HTMLImageElement).src = 'https://cdn.etf-portfolio.com/image/profile/unknown.svg'
  }
}

export function cachedRequest(requestJson: any) {
  const compareRequestJson = JSON.stringify(requestJson)

  for (let i in [...Array(sessionStorage.length).keys()]) {
    const key = sessionStorage.key(Number(i))!
    try {
      if (compareRequestJson == key) {
        return JSON.parse(sessionStorage.getItem(key)!)
      }
    } catch (error: unknown) {
      if (!(error instanceof SyntaxError)) {
        throw error
      } else {
        continue
      }
    }
  }
}

export function autoComplete(): [Ref<any>, Ref<any[] | undefined>, (event: AutoCompleteCompleteEvent) => any, (event: AutoCompleteItemSelectEvent) => void] {
  const etfStore = useEtfStore()
  const router = useRouter()

  const filterKeyword = ref()
  const suggestions = ref()

  function search(event: AutoCompleteCompleteEvent): any {
    const filterKeywords = filterKeyword.value.trim().split(/[ ,]+/).map((word: string) => word.toLowerCase())
    if (filterKeywords) {
      const searchResult: any[] = []
      etfStore.etfDataList.forEach(el => {
        if (el.symbol == 'CASH') return

        let rank = 10

        for (let kw of filterKeywords) {
          try {
            if (el.symbol.toLowerCase().includes(kw)) {
              rank = 1
            } else if (el.name.toLowerCase().includes(kw) && rank > 1) {
              rank = 2
            } else if (el.benchmark.toLowerCase().includes(kw) && rank > 2) {
              rank = 3
            } else if (el.nick.toLowerCase().includes(kw) && rank > 3) {
              rank = 4
            } else if (el.des.toLowerCase().includes(kw) && rank > 4) {
              rank = 5
            }
          } catch {
            console.error(el.symbol)
          }
        }

        if (rank < 10) searchResult.push([rank, el])
      })
      suggestions.value = searchResult.sort((a, b) => a[0] - b[0]).map(([_, { symbol, name }]) => { return symbol + ' - ' + name })
    }
  }

  function moveEtfPage(a: AutoCompleteItemSelectEvent) {
    filterKeyword.value = ''
    router.push(`/etf/${a.value.split(' - ')[0]}`)
  }

  return [filterKeyword, suggestions, search, moveEtfPage]
}

export function checkOS() {
  const userAgent = navigator.userAgent.toLowerCase()

  if (/android/.test(userAgent)) {
    return 'android'
  } else if (/iphone|ipad|ipod/.test(userAgent)) {
    return 'ios'
  } else {
    return 'pc'
  }
}

export function correlationMatrix(source: [name: string, data: number[]][]) {
  const corrMatrix: { name: string, corr: { [index: string]: number } }[] = []
  const count = Math.min(...source.map(el => el[1].length))

  for (let [name, data1] of source) {
    const corr: { [index: string]: number } = {}
    for (let [name2, data2] of source) {
      corr[name2] = Math.round(correlation(data1.slice(-count), data2.slice(-count)) * 100) / 100
    }
    corrMatrix.push({ name, corr })
  }
  return corrMatrix
}

function correlation(data1: number[], data2: number[]) {
  // Calculate mean of each dataset
  const mean1 = data1.reduce((sum, value) => sum + value, 0) / data1.length
  const mean2 = data2.reduce((sum, value) => sum + value, 0) / data2.length

  // Calculate covariance
  let covariance = 0
  for (let i = 0; i < data1.length; i++) {
    covariance += (data1[i] - mean1) * (data2[i] - mean2)
  }
  covariance /= (data1.length - 1)

  // Calculate standard deviations
  const stdDev1 = Math.sqrt(data1.reduce((sum, value) => sum + (value - mean1) ** 2, 0) / (data1.length - 1))
  const stdDev2 = Math.sqrt(data2.reduce((sum, value) => sum + (value - mean2) ** 2, 0) / (data2.length - 1))

  // Calculate correlation coefficient
  const correlationCoefficient = covariance / (stdDev1 * stdDev2)
  return correlationCoefficient
}

export function moveToAnchor(anchor: string) {
  const rectTop = document.querySelector(anchor)?.getBoundingClientRect().top || 0

  window.scrollTo({
    top: rectTop + document.documentElement.scrollTop,
    behavior: 'smooth'
  })
}

export function useScrollInResultComponentCheck() {
  const rectTop = ref(1000)

  function scrollEventListener() {
    rectTop.value = document.querySelector('#result-anchor')?.getBoundingClientRect().top || 1000
  }

  onMounted(() => window.addEventListener('scroll', scrollEventListener))
  onUnmounted(() => window.removeEventListener('scroll', scrollEventListener))

  return computed(() => {
    return window.scrollY >= rectTop.value + document.documentElement.scrollTop - 56
  })
}

type composition = { exchange: string, symbol: string, name: string, price: number, usd_price: number, weight: number }

export function minimizeDeviation(budget: number, assets: composition[], country: string) {
  const targetValues = assets.map(asset => budget * asset['weight'])
  const minQuantities: number[] = []
  const maxQuantities: number[] = []

  for (let i = 0; i < assets.length; i++) {
    const asset = assets[i]
    if (asset.symbol == 'CASH') {
      minQuantities.push(0)
      maxQuantities.push(1)
    } else {
      const assetPrice = country == 'US' ? asset['usd_price'] : asset['price']
      const q = budget * asset['weight'] / assetPrice

      if (q < 0.5) {
        const res: {[index: string]: number} = {}
        assets.forEach((el)=> {
          res[el.symbol] = 0
        })
        return res
      }

      maxQuantities.push(Math.ceil(q))
      minQuantities.push(Math.max(Math.floor(q), 1))
    }
  }

  let minDeviation = Infinity
  let bestCombination: number[] = []

  // 가능한 모든 조합을 생성하기 위한 재귀 함수
  function generateCombinations(index: number, currentCombination: number[]) {
    if (index === assets.length) {
      const totalSpent = currentCombination.reduce((sum, quantity, i) => sum + quantity * assets[i][country == 'US' ? 'usd_price' : 'price'], 0)

      if (totalSpent <= budget) {
        const deviation = currentCombination.reduce((sum, quantity, i) => sum + Math.abs(targetValues[i] - quantity * assets[i][country == 'US' ? 'usd_price' : 'price']), 0)

        if (deviation < minDeviation) {
          minDeviation = deviation
          bestCombination = [...currentCombination]
        }
      }
      return
    }

    for (let i = minQuantities[index]; i <= maxQuantities[index]; i++) {
      currentCombination[index] = i
      generateCombinations(index + 1, currentCombination)
    }
  }

  generateCombinations(0, new Array(assets.length).fill(0))

  const res: {[index: string]: number} = {}
  if (bestCombination.length > 0) {
    bestCombination.forEach((v: any, i: number) => {
      res[assets[i].symbol] = v
    })
  } else {
    assets.forEach((el)=> {
      res[el.symbol] = 0
    })
  }

  return res
}

export function isNumericString(str: string) {
  return /^[0-9]+$/.test(str);
}

export function defaultObject(defaultValue: any) {
  const newObj: {[index: string | number | symbol]: any} = {}
  return new Proxy(newObj, {
    get: (target, key: string | number | symbol) => {
      if (!(key in target)) {
        if (typeof defaultValue === 'function') {
          target[key] = defaultValue()
        } else {
          if (Array.isArray(defaultValue)) {
            target[key] = []
          } else {
            target[key] = defaultValue
          }
        }
      }
      return target[key]
    }
  })
}
